<template>
  <div class="relative flex h-screen lg:items-center">
    <div class="hidden h-screen w-1/2 md:block">
      <img
        src="~/assets/images/login-bg.jpg"
        class="size-full object-cover object-left"
        alt=""
      >
    </div>
    <div class="w-2/3 flex-none shrink-0 justify-center overflow-y-auto max-md:w-full lg:flex">
      <slot />
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
